<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row
      align="center"
      class="d-none d-sm-flex"
    >
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-account-switch"
          title="Komunitas"
          :value="String(Summary.total)"
        />
      </v-col>
    </v-row>
    <!--  -->
    <v-row
      align="center"
      justify="center"
      class="d-flex d-sm-none"
    >
      <v-col
        cols="12"
        style="padding: 0"
      >
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            <v-badge
              color="green"
              :content="item.summary"
            >
              {{ item.title }}
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
          >
            <v-card flat>
              <v-card-text>
                <v-col
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <base-material-stats-card
                    color="info"
                    :icon="item.icon"
                    :title="item.title"
                    :value="String(item.summary)"
                    style="cursor: pointer;"
                    @click.native="setStatus(item.status)"
                  />
                </v-col>
                {{ item.content }}
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
      >
        <v-text-field
          v-model="q"
          append-icon="mdi-magnify"
          :label="$t('search')"
          color="secondary"
          hide-details
          @keyup="Search"
        />
      </v-col>
      <!--  -->
      <v-col
        cols="12"
        md="6"
        lg="3"
        offset-md="6"
        offset-lg="6"
      >
        <v-btn
          to="/community/create"
          left
          width="100%"
          color="primary"
        >
          <span class="mr-1">
            <v-icon light>mdi-pencil-plus</v-icon>
          </span>
          Buat Baru
        </v-btn>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="12"
      >
        <base-material-card
          icon="mdi-account-multiple"
          color="primary"
          class="px-6 py-3"
          title="Komunitas - All"
        >
          <template>
            <div
              v-if="!isLoading"
              class="text-center mt-5 mb-5"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </div>
            <div v-else>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-left primary--text">
                      #
                    </th>
                    <th class="text-left primary--text">
                      Nama Komunitas
                    </th>
                    <th class="text-left primary--text">
                      Prefix
                    </th>
                    <th class="text-left primary--text">
                      Username
                    </th>
                    <th class="text-left primary--text">
                      Email
                    </th>
                    <th class="text-left primary--text">
                      Kontak
                    </th>
                    <th class="text-left primary--text">
                      Terdaftar Pada
                    </th>
                    <th class="text-center primary--text">
                      Aksi
                    </th>
                  </tr>
                </thead>
                <!--  -->
                <tbody>
                  <tr
                    v-for="(x, index) in Komunitas.data"
                    :key="x.id"
                  >
                    <td>{{ index + Komunitas.meta.from }}</td>
                    <td>{{ x.name }}</td>
                    <td>{{ x.prefix !== null ? x.prefix : ' - ' }}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ x.created_at | moment('D MMM YYYY') }}</td>
                    <td class="text-center">
                      <v-tooltip
                        bottom
                        color="blue"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="blue"
                            v-bind="attrs"
                            :to="'/community/' + x.id + '/show'"
                            v-on="on"
                          >
                            <v-icon small>
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>views</span>
                      </v-tooltip>

                      <v-tooltip
                        bottom
                        color="red"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <!--  -->
                          <v-btn
                            color="red lighten-2"
                            icon
                            v-bind="attrs"
                            @click="openDialog(x.id, x.name)"
                            v-on="on"
                          >
                            <v-icon small>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <div class="text-center">
                <v-pagination
                  v-model="page"
                  :length="Komunitas.meta.last_page"
                  total-visible="5"
                  @input="getResults"
                />
              </div>
            </div>
          </template>
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <template>
          <v-dialog
            v-model="dialog.open"
            width="500"
            :retain-focus="false"
          >
            <v-card>
              <v-card-title primary-title>
                Confirmation
              </v-card-title>

              <v-card-text>
                Anda yakin akan menghapus data Komunitas dengan judul "{{
                  dialog.title
                }}"?
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="dialog.open = false"
                >
                  BATAL
                </v-btn>
                <v-spacer />
                <v-btn
                  color="error"
                  @click="deleteItem(dialog.id)"
                >
                  HAPUS
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'Comunity',
    //
    data () {
      return {
        Komunitas: {
          data: {},
          meta: {},
        },
        Summary: {
          total: 0,
        },
        dialog: {
          open: false,
          id: 0,
          title: '',
        },
        tab: null,
        items: [
          {
            tab: 'One',
            title: 'Komunitas',
            icon: 'mdi-account-switch',
            summary: '0',
            status: '',
          },
        ],
        isLoading: false,
        page: parseInt(this.$route.query.page) || 1,
        q: this.$route.query.q || '',
      }
    },
    watch: {
      q (newVal) {
        this.$router.push({ query: { ...this.$route.query, q: newVal } })
      },
      '$route.query.q': function (val) {
        this.q = val
      },
      page (newVal) {
        this.$router.push({ query: { ...this.$route.query, page: newVal } })
      },
      '$route.query.page': function (val) {
        this.page = parseInt(val)
      },
    },
    mounted () {
      this.getResults()
      this.getSummary()
    },
    methods: {
      openDialog (id, name) {
        this.dialog.id = id
        this.dialog.title = name
        this.dialog.open = true
      },
      deleteItem (id) {
        this.dialog.open = false
        axios.delete('/v1/community/' + id).then(response => {
          if (response.data.meta.status) {
            this.$toast.error('Berhasil menghapus data', {
              type: 'error',
              position: 'top-right',
              duration: 3000,
              dismissible: true,
            })
          } else {
            console.log(response.data)
          }

          // Update
          this.getResults()
        })
      },
      Remove (id) {
        axios.delete('/v1/community/' + id).then(response => {
          this.$toast.error('sukses Hapus Keranjang', {
            type: 'error',
            position: 'top-right',
            duration: 3000,
            dismissible: true,
          })
          // Update
          this.getResults()
        })
      },
      Search () {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.getResults()
        }, 700)
      },
      setKomunitas (data) {
        this.Komunitas = data
      },
      getResults (page = this.page) {
        this.isLoading = false
        const params = {
          q: this.q,
          sort: '-id',
          page: this.page,
        }
        axios
          .get('/v1/community?', { params: params })
          .then(response => {
            this.setKomunitas(response.data)
            this.isLoading = true
          })
          .catch(E => {
            console.error(E)
          })
      },
      setSummary (data) {
        this.Summary = data
        this.items.forEach(item => {
          item.summary = this.Summary.total
        })
      },
      getSummary () {
        axios
          .get('/v1/community/summary')
          .then(response => {
            this.setSummary(response.data.data)
          })
          .catch(e => {})
      },
    },
  }
</script>
